import { IonContent, IonInput, IonLabel, IonButton, IonItem, IonImg, IonPage, IonIcon } from '@ionic/vue';
import { callOutline, lockClosedOutline } from 'ionicons/icons';
import { fetchToken, getInfo, setupIntercom } from '@/services/users';
export default {
  name: 'Connexion',
  components: {
    IonContent,
    IonInput,
    IonLabel,
    IonButton,
    IonItem,
    IonImg,
    IonPage,
    IonIcon
  },

  data() {
    return {
      userInfo: {
        email: '',
        password: ''
      },
      errors: {}
    };
  },

  setup() {
    return {
      callOutline,
      lockClosedOutline
    };
  },

  mounted() {
    // When user is here, always reset Matomo ID
    const _paq = window._paq = window._paq || [];

    _paq.push(['resetUserId']);

    _paq.push(['appendToTrackingUrl', '']);
  },

  methods: {
    /**
     * Allows you to see if the data entered by the user is correct
     */
    async handleLogin() {
      const {
        errors
      } = await fetchToken(this.userInfo);
      this.errors = errors;

      if (this.errors.email || this.errors.password) {
        return;
      }

      const {
        userData
      } = await getInfo();

      if (userData) {
        setupIntercom({
          userData
        });

        if (userData.agent.kind === 'driver') {
          this.$router.push('/planning');
        } else if (userData.company.kind === 'renter') {
          this.$router.push('/el/planning');
        } else if (userData.company.kind === 'builder') {
          console.log('Partie de la plateforme pour cet utilisateur pas encore terminée');
        }

        return;
      }
    }

  }
};