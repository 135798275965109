import { IonRow, IonCol, IonLabel, IonImg, IonChip, IonIcon, popoverController } from '@ionic/vue';
import { closeCircle, addCircle } from 'ionicons/icons';
import PopoverAddMachine from '@/components/driver/popover/PopoverAddMachine';
import PopoverAddTool from '@/components/driver/popover/PopoverAddTool';
export default {
  name: 'TransferDetails',
  components: {
    IonRow,
    IonCol,
    IonLabel,
    IonImg,
    IonChip,
    IonIcon
  },
  props: ['machines', 'tools', 'oldRentalCouponSending'],

  setup() {
    return {
      closeCircle,
      addCircle
    };
  },

  methods: {
    deleteMachine(machineToRemove) {
      let newMachines = this.machines;
      const index = newMachines.indexOf(machineToRemove);

      if (index > -1) {
        newMachines.splice(index, 1);
      }

      this.$emit('update:machines', newMachines);
    },

    deleteTool(toolToRemove) {
      let newTools = this.tools;
      const index = newTools.indexOf(toolToRemove);

      if (index > -1) {
        newTools.splice(index, 1);
      }

      this.$emit('update:tools', newTools);
    },

    async addMachine() {
      if (this.oldRentalCouponSending) {
        return;
      }

      const popover = await popoverController.create({
        component: PopoverAddMachine,
        componentProps: {
          closePopover: newMachine => {
            let newMachines = this.machines;
            newMachines.push(newMachine);
            this.$emit('update:machine', newMachines);
            popover.dismiss();
          }
        }
      });
      await popover.present();
      await popover.onDidDismiss();
    },

    async addTool() {
      if (this.oldRentalCouponSending) {
        return;
      }

      const popover = await popoverController.create({
        component: PopoverAddTool,
        componentProps: {
          closePopover: newTool => {
            let newTools = this.tools;
            newTools.push(newTool);
            this.$emit('update:tools', newTools);
            popover.dismiss();
          }
        }
      });
      await popover.present();
      await popover.onDidDismiss();
    }

  }
};