import { IonRow, IonCol, IonLabel, IonImg, IonChip, IonIcon, IonSelect, IonSelectOption, IonInput, IonToggle, popoverController } from '@ionic/vue';
import { closeCircle, addCircle } from 'ionicons/icons';
import PopoverAddMaterial from '@/components/driver/popover/PopoverAddMaterial';
export default {
  name: 'DeliveryDetails',
  components: {
    IonRow,
    IonCol,
    IonLabel,
    IonImg,
    IonChip,
    IonIcon,
    IonSelect,
    IonSelectOption,
    IonInput,
    IonToggle
  },
  props: ['materials', 'rounds', 'tonsByRound', 'allRoundsType', 'toggleQuantityTools', 'oldRentalCouponSending'],

  setup() {
    return {
      closeCircle,
      addCircle
    };
  },

  methods: {
    onChangeToggleQuantity(value) {
      this.$emit('update:toggleQuantityTools', value);
    },

    onChangeRounds(obj) {
      this.$emit('update:rounds', obj);
      this.updateTonsByRound(obj);
    },

    onChangeTonsByRound(name, value) {
      let newTonsByRound = this.tonsByRound;

      for (const key in newTonsByRound) {
        if (newTonsByRound[key].name === name) {
          newTonsByRound[key].value = value;
          this.$emit('update:tonsByRound', newTonsByRound);
          return;
        }
      }
    },

    deleteMaterial(materialToRemove) {
      let newMaterials = this.materials;
      const index = newMaterials.indexOf(materialToRemove);

      if (index > -1) {
        newMaterials.splice(index, 1);
      }

      this.$emit('update:materials', newMaterials);
    },

    async addMaterial() {
      if (this.oldRentalCouponSending) {
        return;
      }

      const popover = await popoverController.create({
        component: PopoverAddMaterial,
        componentProps: {
          closePopover: newMaterial => {
            let newMaterials = this.materials || [];
            newMaterials.push(newMaterial);
            this.$emit('update:materials', newMaterials);
            popover.dismiss();
          }
        }
      });
      await popover.present();
      await popover.onDidDismiss();
    },

    updateTonsByRound(obj) {
      const newTonsByRound = {};
      const isAllDay = obj.name === 'À la journée';
      const isCustom = obj.isCustom;
      const length = isCustom || isAllDay ? 1 : obj.value;

      for (let i = 0; i < length; i++) {
        let name = `Tour ${i + 1}`;

        if (isAllDay || isCustom) {
          name = 'Total';
        }

        newTonsByRound[i] = {
          name: name,
          value: this.tonsByRound[i] ? this.tonsByRound[i].value : ''
        };
      }

      this.$emit('update:tonsByRound', newTonsByRound);
    }

  },
  computed: {
    totalTonsByRound() {
      let total = 0;
      Object.values(this.tonsByRound).forEach(round => {
        if (round.value) {
          total += parseFloat(round.value);
        }
      });
      return total;
    }

  }
};