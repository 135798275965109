import { IonPage, IonContent, IonGrid, IonRow, IonCol, IonButton, IonLabel, IonToggle, IonBadge } from '@ionic/vue';
import Date from '@/components/driver/rentalCoupon/components/Date';
import ClientDetails from '@/components/driver/rentalCoupon/components/ClientDetails';
import Prestation from '@/components/driver/rentalCoupon/components/Prestation';
import Signature from '@/components/driver/rentalCoupon/components/Signature';
import InternalInformation from '@/components/driver/rentalCoupon/components/InternalInformation';
import InternalReferences from '@/components/driver/rentalCoupon/components/InternalReferences';
import Options from '@/components/driver/rentalCoupon/components/Options';
import { mixinDataAllTypeRentalCoupon } from '@/components/driver/rentalCoupon/mixins/DataAllTypeRentalCoupon';
import { mixinDataTransportRentalCoupon } from '@/components/driver/rentalCoupon/mixins/DataTransportRentalCoupon';
import { getDetailMission, getMissionOrder } from '@/services/driver/missionsOrders';
import { getInfo } from '@/services/users';
import { fetchRentalCoupon } from '@/services/driver/rentalCoupon';
import TransferDetails from '@/components/driver/rentalCoupon/components/TransferDetails';
import WhiteHeader from '@/components/driver/header/WhiteHeader';
import utils from '@/services/utils/Utils';

const dayjs = require('dayjs');

require('dayjs/locale/fr');

dayjs.locale('fr');
export default {
  name: 'RentalTransfer',
  components: {
    WhiteHeader,
    TransferDetails,
    Options,
    InternalInformation,
    InternalReferences,
    Signature,
    Prestation,
    ClientDetails,
    Date,
    IonPage,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonLabel,
    IonToggle,
    IonBadge
  },
  mixins: [mixinDataAllTypeRentalCoupon, mixinDataTransportRentalCoupon],

  data() {
    return {
      machines: [],
      tools: []
    };
  },

  async mounted() {
    // Get informations on the mission
    this.mission = await getMissionOrder(this.$route.params.id, this.$route.params.idRide, this.typeMission, this.$route.params.idRentalCoupon);

    for (const transfer in this.mission['transport_items']) {
      // first case : we have an item, so it's the id of the item
      // second case : we don't have an item, so it's the id of the rental_coupon
      if (transfer === this.$route.params.idItem || transfer === this.$route.params.idRentalCoupon) {
        this.missionItem = this.mission['transport_items'][transfer];
      }
    }

    if (!this.missionItem.pricing_information) {
      utils.routeForbidden(this.$router, this.$route, 'transfert');
    }

    this.missionDetail = await getDetailMission(this.typeMission, this.$route.params.id, this.$route.params.idItem, this.$route.params.idRentalCoupon);
    const {
      userData
    } = await getInfo();
    this.user = userData;
    this.initializationSignatureData();
    this.startDefault = this.mission.start;
    this.endDefault = this.mission.end;
    this.source_builder_name = this.missionItem.source;
    this.target_builder_name = this.missionItem.target;
    this.toggleCharge = true;
    this.toggleDischarge = true;

    if (this.typeVue !== 'create') {
      this.oldRentalCoupon = await fetchRentalCoupon(this.$route.params.idRentalCoupon);

      if (this.oldRentalCoupon.status === 'send') {
        this.oldRentalCouponSending = true;
      }

      this.start = this.oldRentalCoupon.start;
      this.end = this.oldRentalCoupon.end;

      if (this.oldRentalCoupon.start < this.startDefault) {
        this.startDefault = this.oldRentalCoupon.start;
      }

      if (this.oldRentalCoupon.end > this.endDefault) {
        this.endDefault = this.oldRentalCoupon.end;
      }

      this.pricing_fuel = this.oldRentalCoupon.pricing_fuel;
      this.pricing_driver = this.oldRentalCoupon.pricing_driver;
      this.comments = this.oldRentalCoupon.comments;
      this.old_draw_signature_contact = this.oldRentalCoupon.signature_contact;
      this.old_draw_signature_driver = this.oldRentalCoupon.signature_driver;
      this.no_signature_contact = this.oldRentalCoupon.no_signature_contact;
      this.setToggle(this.typeMission);
      this.setModifiersKeys(this.oldRentalCoupon.modifiers);
    } else {
      this.start = this.mission.start;
      this.end = this.mission.end;
      this.pricing_fuel = this.missionItem.pricing_fuel;
      this.pricing_driver = this.missionItem.pricing_driver;
    }

    this.source_worksite_name = this.setWorksiteTextForm(this.oldRentalCoupon.source_worksite_name, this.missionItem.source_location_name, this.missionItem.source_location_address, this.missionItem.source_location_city, this.missionItem.source_location_manual);
    this.target_worksite_name = this.setWorksiteTextForm(this.oldRentalCoupon.target_worksite_name, this.missionItem.target_location_name, this.missionItem.target_location_address, this.missionItem.target_location_city, this.missionItem.target_location_manual);
    await this.setSourceSupervisorsInformationForm();
    await this.setTargetSupervisorsInformationForm();
    this.setMachinesToolsToTransfer();
    this.setInformationsDateDependent();
    this.setInternalReferences();

    if (this.typeVue === 'create') {
      this.setSelectedModifiers(this.missionItem.modifiers);
    }
  },

  methods: {
    setMachinesToolsToTransfer() {
      if (this.typeVue !== 'create') {
        this.machines = this.oldRentalCoupon.machines;
        this.tools = this.oldRentalCoupon.tools;
        return;
      }

      if (this.missionItem.machines) {
        this.missionItem.machines.forEach(machine => {
          this.machines.push(machine.name);
        });
      }

      if (this.missionItem.machines_manual) {
        this.machines = this.machines.concat(this.missionItem.machines_manual);
      }

      if (this.missionItem.tools) {
        for (const tool in this.missionItem.tools) {
          this.tools.push(this.missionItem.tools[tool].name);
        }
      }

      if (this.missionItem.tools_manual) {
        this.tools = this.tools.concat(this.missionItem.tools_manual);
      }
    },

    async sendRentalCoupon() {
      if (this.missionItem.coupon_company && !this.source_contact_email && !this.target_contact_email && !this.source_contact_phone && !this.target_contact_phone && this.status === 'send' && (await this.sendWithNoEmail())) {
        return;
      }

      if (this.typeVue !== 'create' && this.oldRentalCoupon.status !== 'save') {
        this.rentalCoupon = this.oldRentalCoupon;
        this.rentalCoupon.source_contact_email = this.source_contact_email;
        this.rentalCoupon.target_contact_email = this.target_contact_email;
        this.rentalCoupon.notes = this.notes;
      } else {
        const {
          userData
        } = await getInfo();
        this.rentalCoupon = {
          ride_id: this.mission.id,
          transfer_id: this.mission.id_transport,
          item_id: this.$route.params.idItem,
          driver_id: userData.agent_id,
          machine_id: this.mission.machine_id,
          status: this.status,
          start: this.start,
          end: this.end,
          machines: this.machines,
          tools: this.tools,
          source_builder_id: null,
          source_builder_name: null,
          source_contact_id: null,
          source_contact_name: null,
          source_contact_email: this.source_contact_email,
          source_worksite_id: null,
          source_worksite_name: null,
          target_builder_id: null,
          target_builder_name: null,
          target_contact_id: null,
          target_contact_name: null,
          target_contact_email: this.target_contact_email,
          target_worksite_id: null,
          target_worksite_name: null,
          prestation_times: this.completeTimes(this.times.prestation),
          bad_weather_times: this.completeTimes(this.times.badWeather),
          maintenance_times: this.completeTimes(this.times.maintenance),
          pricing_fuel: this.pricing_fuel,
          pricing_driver: this.pricing_driver,
          comments: this.comments,
          signature_contact: null,
          signature_driver: null,
          no_signature_contact: this.no_signature_contact,
          coupon_company: this.missionItem.coupon_company,
          billing_company_id: this.missionItem.billing_company_id,
          notes: this.notes,
          worksite_reference: this.worksite_reference,
          order_number: this.order_number,
          pricing_information: this.mission.pricing_information,
          amount_flat_rates: this.mission.amount_flat_rates,
          added_modifiers: Object.values(this.selectedModifiers),
          custom_articles: this.missionItem.custom_articles,
          custom_modifiers: this.missionItem.custom_modifiers
        };
        this.completeSourceBuilder();
        this.completeSourceContact();
        this.completeSourceWorksite();
        this.completeTargetBuilder();
        this.completeTargetContact();
        this.completeTargetWorksite();
        this.completeMachineCounteur();
        this.saveSignatureContact();
        this.saveSignatureDriver();
      }

      if (this.typeVue !== 'create') {
        this.rentalCoupon['id'] = this.oldRentalCoupon.id;
      }

      await this.send();
    },

    setModifiersKeys(modifiers) {
      modifiers.forEach(modifier => {
        this.selectedModifiers[modifier.name] = modifier;
      });
    }

  }
};